@import "../../../Assets/scss/base";

.childStory {
  &-card {
    @include border-radius(10px);
    background: $white-color;
    padding: 15px;
    overflow: $hidden;
    box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.1);
    @include transition(all, 0.6s, ease-in-out);
    height: 100%;
    padding-bottom: 50px;
    figure {
      position: $relative;
      display: $block;
      width: 100%;
      padding: 0;
      overflow: $hidden;
      margin-bottom: 10px;
      @include transition(all, 0.6s, ease-in-out);
      @include border-radius(10px 10px 0 0);
      max-height: 250px;

      &::before {
        padding-top: 75%;
        display: $block;
        content: "";
      }

      img {
        position: $absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        max-height: 250px;
        object-fit: $cover !important;
        @include transition(all, 0.6s, ease-in-out);
        @include border-radius(10px 10px 0 0);
      }
    }

    &:hover {
      @include transform(translateY(-10px));
      box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;

      figure {
        img {
          @include transform(scale(1.1));
        }
      }
    }
  }

  &-content {
    h6 {
      margin-bottom: 0;
    }

    p {
      @include font-size(1);
      font-weight: $font-regular;
      line-height: 1.3;
      margin-bottom: 0;
    }

    &_footer {
      position: $absolute;
      bottom: 10px;
      width: calc(100% - 30px);
      margin: 0 $auto;

      span {
        font-weight: $font-bold;
        @include font-size(0.875);
        line-height: 1.3;
        width: calc(100% - 80px - 15px);
        @media (min-width: 992px) {
          width: calc(100% - 100px - 15px);
          @include font-size(0.813);
        }
      }

      em {
        width: 80px;
        @media (min-width: 992px) {
          width: 100px;
        }

        img {
          object-fit: $contain !important;
          max-width: 80px;

          @media (min-width: 992px) {
            max-width: 100px;
          }
        }
      }
    }
  }
}

.trim-care-program-description {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* Number of lines to display */
  -webkit-box-orient: vertical;
  overflow-wrap: break-word;
}
