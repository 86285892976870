@import "../../Assets/scss/base";

.leadershipTeam {
    .leadershipCard {
        @include border-radius(15px);
        @include transition(all, 0.6s, ease-in-out);
        height: 100%;
        overflow: $hidden;
        figure {          
            max-height: 300px;
            width: 300px;
            height: 300px;
            clip-path: circle(63.7% at 35% 35%);
            overflow: $hidden;
            @media (max-width:1199.98px) {
                max-height: 250px;
                width: 250px;
                height: 250px;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: $cover;
                max-height: 300px;   
                @media (max-width:1199.98px) {
                    max-height: 250px;                   
                }           
            }
        }
        &--summary {
            padding:1rem;
            @include flexbox;
            @include flex-flow(column);
            gap:0.5rem;
            @media (min-width:1200px) {
                padding: 1.5rem;
            }
            h3 ,.h3{
                margin-bottom: 0;
                overflow-wrap: break-word;
            }
        }
        &-text {
            p {
                @include font-size(0.875);               
                line-height: 1.5;
                overflow-wrap: break-word;
            }
        }
        &:hover {
            background: $white-color;
            box-shadow: 4px 4px 25px 0px #D4D4E8;
        }
    }
}