@import "../../../Assets/scss/_base.scss";

.loader {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.15);
  z-index: 2000000;
  position: fixed;
  .loader_class {
    position: $absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100%;
    z-index: 2000000;
    background-color: rgba(0, 0, 0, 0.15);
    svg {
      position: $absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }
}
