@import "../../../Assets/scss/base";

.sub-header {
  background:
    url(../../../Assets/Images/icons/ring.svg) no-repeat,
    linear-gradient(111deg, $primary-color 0%, $primary-color-500 70.9%);
  background-size: contain;
  background-position: top left;
  height: auto;
  @include flexbox;
  @include align-items(center);
  padding: 15px 0;

  @media (min-width: 1200px) {
    height: auto;
  }

  h2,
  .h2 {
    color: $white-color !important;
    @include flexbox;
    @include align-items(end);
    margin-bottom: 0;
  }
}

.common-heading-subheader {
  color: $white-color;
  font-style: normal;
  letter-spacing: 0.1875rem;
  text-transform: $capitalize;
  text-align: $center;
}