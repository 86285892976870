@import "../../../Assets/scss/base";

.datePickerBox {
  @media (max-width: 575px) {
    width: 100%;
  }

  .react-datepicker {
    &-wrapper {
      width: 100%;
      @media (max-width: 575px) {
        width: 100%;
      }
    }

    &__input-container {
      width: 100%;
      input {
        padding-right: 35px;
        background:
          url("../../../Assets/Images/icons/calendar-clock.svg") no-repeat,
          $white-color;
        background-size: 23px;
        background-position: 98% 50%;
        cursor: $pointer;
        @media (max-width: 991px) {
          background-size: 20px;
          background-position: 98% 50%;
        }
      }
    }

    &__close-icon {
      &::after {
        width: 25px;
        height: 25px;
        background-color: $primary-color;
        font-size: 1rem;
        z-index: 1;
        position: $relative;
        line-height: 1.3;
      }

      &::before {
        width: 30px;
        height: 30px;
        background-color: $white-color;
        font-size: 1rem;
        content: "";
        display: block;
        position: absolute;
        z-index: 0;
        right: 5px;
        top: 50%;
        @include transform(translateY(-50%));
      }
    }
  }
}

.react {
  &-datepicker {
    &-popper {
      .react {
        &-datepicker {
          border-color: rgba($primary-color, 0.65);

          &__triangle {
            &::before {
              border-bottom-color: $primary-color !important;
            }
          }

          &__navigation {
            &:hover {
              .react {
                &-datepicker {
                  &__navigation {
                    &-icon {
                      &:before {
                        border-color: rgba($primary-color, 0.95);
                      }
                    }
                  }
                }
              }
            }

            &--previous {
            }

            &--next {
            }
          }

          // month contianer
          &__month-container {
            .react {
              &-datepicker {
                &__month {
                  .react {
                    &-datepicker {
                      &__week {
                        .react {
                          &-datepicker {
                            &__day {
                              &--selected,
                              &--keyboard-selected,
                              &--today {
                                background-color: $primary-color;
                                color: $white-color;
                              }

                              &:hover {
                                background-color: rgba($primary-color, 0.25);
                                color: $base-color;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          // month container
          &__header {
            background-color: rgba($primary-color, 0.1);
          }

          // time container
          &__time-container {
            .react {
              &-datepicker {
                &__time {
                  &-box {
                  }

                  &-list {
                    scrollbar-color: $primary-color transparent;
                    scrollbar-width: thin;

                    &-item {
                      &:hover {
                        background-color: rgba($primary-color, 0.25) !important;
                        color: $base-color !important;
                      }

                      &--selected {
                        background-color: $primary-color !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      &[data-placement^="top"] {
        .react {
          &-datepicker {
            &__triangle {
              &::before {
                border-top-color: $primary-color !important;
              }
            }
          }
        }
      }
    }
  }
}
