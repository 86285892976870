@import "../../../Assets/scss/base";

.children_program_slider {
  .child-slider {
    margin: 0 -15px;
    padding: 0 15px;
    @media (max-width: 767px) {
      margin: 0;
      padding: 0px;
    }

    .slick-list {
      .slick-track {
        @include flexbox;
        padding: 30px 0;
        .slick-slide {
          padding: 0 15px;
          height: inherit;
          & > div {
            height: 100%;
          }
          img {
            object-fit: $cover;
          }
        }
      }
    }

    .slick {
      &-arrow {
        z-index: 9;

        &::before {
          display: none;
        }

        svg {
          width: 22px;
          height: 22px;
        }
      }

      &-disabled {
        opacity: 0.5;
        cursor: none;
        pointer-events: none;
        svg {
          opacity: 0.5;
        }
      }

      &-prev {
        left: -5px;
        @media (max-width: 991px) {
          left: -10px;
        }
      }

      &-next {
        right: -5px;
        @media (max-width: 991px) {
          right: -10px;
        }
      }
    }

    ul {
      &.slick-dots {
        li {
          button {
            padding: 0;
            width: 18px;
            height: 18px;
            border: 2px solid rgba($primary-color, 0.5);
            border-radius: 50px;
            margin: 0;
            position: $relative;
            &::before {
              content: "";
              background: $primary-color;
              width: 8px;
              height: 8px;
              @include border-radius(100vmax);
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
            }
          }

          &.slick-active {
            button {
              border-color: $primary-color;

              &::before {
                background: $primary-color;
              }
            }
          }
        }
      }
    }
  }

  //css for slider box
  .partners_slider {
    &-box {
      overflow: hidden;
      position: relative;
      @include transition(all, 0.5s, ease-in-out);
      @include border-radius(10px);
      height: 250px;

      @media (min-width: 1200px) {
        height: 250px;
      }

      @media (max-width: 767.98px) {
        height: 250px;
      }
      &::before {
        background: linear-gradient(
          to top,
          rgba(0, 0, 0, 0.7) 0%,
          transparent 50%
        );
        transform: translateY(20%);
        /* transition: transform .85s cubic-bezier(0.23, 1, 0.32, 1); */
        position: $absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: "";
        opacity: 1;
        z-index: 1;
        @include transition(all, 0.5s, ease-in-out);
      }

      img {
        max-width: 100%;
        width: 100%;
        max-height: 250px;
        height: 100%;
        @include border-radius(10px);
        outline: 0;
        @include transition(all, 0.5s, ease-in-out);

        @media (min-width: 1200px) {
          max-height: 250px;
        }

        @media (max-width: 767.98px) {
          max-height: 250px;
        }
      }

      &:hover {
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);

        &::before {
          transform: translateY(0%);
        }

        img {
          transform: scale(1.1);
        }
      }
    }
  }
}
