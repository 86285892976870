@import "../../../Assets/scss/base";

.custom-slider {
  margin: 0 -10px;
  @media (max-width: 767px) {
    margin: 0;
  }
  .slick-list {
    .slick-track {
      display: flex;

      .slick-slide {
        margin-right: 0px;
        margin-left: 0px;
        img {
          object-fit: scale-down;
        }
      }
    }
  }

  .slick {
    &-arrow {
      z-index: 9;
      &::before {
        display: none;
      }
      svg {
        width: 22px;
        height: 22px;
      }
    }
    &-disabled {
      opacity: 0.5;
      svg {
        opacity: 0.5;
      }
    }

    &-prev {
      left: 0;
      @media (max-width: 991px) {
        left: 0;
      }
    }

    &-next {
      right: 0;
      @media (max-width: 991px) {
        right: 0;
      }
    }
  }

  ul {
    &.slick-dots {
      li {
        button {
          padding: 0;
          width: 18px;
          height: 18px;
          border: 2px solid rgba($primary-color, 0.5);
          border-radius: 50px;
          margin: 0;
          position: relative;

          &::before {
            content: "";
            background: $primary-color;
            width: 8px;
            height: 8px;
            @include border-radius(100vmax);
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }

        &.slick-active {
          button {
            border-color: $primary-color;
            &::before {
              background: $primary-color;
            }
          }
        }
      }
    }
  }
}
