@import "../../Assets/scss/base";

.success-wrapper {
  min-height: calc(100vh - 96px - 40px);
}
.card {
  &.success_card {
    @include border-radius(15px);
    padding: 20px;
    @include flexbox;
    @include flex-flow(column);
    gap: 30px;
    max-width: 600px;
    margin: 0 $auto;
    @include box-shadow(0px, 2px, 20px, rgba($base-color, 0.025));
    @media (min-width: 768px) {
      padding: 30px;
    }
  }
}
