@import "../../../Assets/scss/base";

.videoBox {
  max-width: 600px;
  margin: 0px auto;
}

.floating_cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  text-align: left;
  gap: 30px;
  @media (max-width: 767.98px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 575.98px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
  }
  &--items {
    background-color: $white-color;
    @include border-radius(10px);
    padding: 2.5rem 1.5rem;
    box-shadow: -5px 18px 60px 0px rgba(210, 210, 234, 0.4);
    @include flexbox;
    @include flex-flow(column);
    @include justify-content(space-between);
    gap: 30px;
    @include transition(all, 0.5s, ease-in-out);
    @media (max-width: 767.98px) {
      padding: 1rem;
      gap: 20px;
    }
    &:nth-child(2n) {
      margin-top: 60px;
      @media (max-width: 767.98px) {
        margin-top: -30px;
        margin-bottom: 30px;
      }
      @media (max-width: 575.98px) {
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }
    &:nth-child(2n + 1) {
      margin-bottom: 60px;
      @media (max-width: 767.98px) {
        margin-top: 30px;
        margin-bottom: -30px;
      }
      @media (max-width: 575.98px) {
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }
    .flex_grp {
      @include flexbox;
      @include flex-flow(column);
      @include justify-content(space-between);
      gap: 10px;
    }

    em {
      width: 50px;
      height: 50px;
      @include border-radius(100vmax);
      background: rgba($primary-color, 0.25);
      margin-right: 15px;
      @media (max-width: 1199.98px) {
        width: 32px;
        height: 32px;
      }
      &.light-red {
        background: rgba($red-color, 0.15);
        box-shadow: 0px 2px 10px rgba($red-color, 0.25);
        box-shadow: 6px 15px 30px 0px rgba(188, 0, 50, 0.07);
      }
      &.light-blue {
        background: rgba($sky-blue-color, 0.15);
        box-shadow: 0px 2px 10px rgba($sky-blue-color, 0.25);
        box-shadow: 6px 15px 30px 0px rgba(0, 101, 150, 0.08);
      }
      &.light-pink {
        background: rgba($pink-color, 0.15);
        box-shadow: 6px 15px 30px 0px rgba(100, 4, 151, 0.08);
      }
    }
    .cardTitle {
      h6 {
        @include font-size(1);
      }
    }
    p {
      @include font-size(1);
      line-height: 1.3;
    }
    .learn_more {
      a {
        text-decoration: none;
        text-transform: $uppercase;
        color: rgba($base-color, 0.85) !important ;
        @include font-size(0.875);
        &:hover {
          color: rgba($primary-color, 0.85) !important ;
        }
      }
    }
    &:hover {
      @include transform(translateY(-20px));
    }
  }
}

.applyNowLink {
  @media (min-width: 576px) {
    position: $absolute;
    right: 0;
    top: 50%;
    @include transform(translateY(-50%));
  }
  &:hover {
    text-decoration: $underline;
    color: $primary-color-500;
  }
}

.learn_more {
  text-decoration: underline !important;
 
}