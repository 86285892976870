// _theme.scss

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &::after,
  &::before {
    box-sizing: border-box;
  }
}

html {
  scrollbar-color: $primary-red rgba($grey-color-400, 0.95);
  scrollbar-width: thin;
  body {
    margin: 0;
    padding: 0;
    background: $body-color;
    color: $base-color;
    font-family: $Public-font;
    letter-spacing: 0.01em;
    font-family: var(--bs-body-font-family);
    font-weight: $font-regular;
    font-size: $f16;
    overflow-x: $hidden;
    scrollbar-color: $primary-red rgba($grey-color-400, 0.95);
    scrollbar-width: thin;

    h1,
    .h1,
    h2,
    .h2,
    h3,
    .h3,
    h4,
    .h4,
    h5,
    .h5,
    h6,
    .h6 {
      font-weight: $font-semibold;
      font-family: $Public-font;
      line-height: 1.3;
      word-break: break-word;
      color: $black-color-200;
    }
  }
}

h1,
.h1 {
  @media (min-width: 1441px) {
    @include font-size(3.25);
  }

  @media (max-width: 1440px) {
    @include font-size(2.5);
  }

  @media (max-width: 991px) {
    @include font-size(2.125);
  }
}

h2,
.h2 {
  letter-spacing: -0.0125em;
  @media (min-width: 1441px) {
    @include font-size(2.125);
  }
  @media (max-width: 1440px) {
    @include font-size(2);
  }

  @media (max-width: 991px) {
    @include font-size(1.75);
  }
}

h3,
.h3 {
  @media (min-width: 1441px) {
    @include font-size(1.875);
  }

  @media (max-width: 1440px) {
    @include font-size(1.75);
  }

  @media (max-width: 991px) {
    @include font-size(1.5);
  }
}

h4,
.h4 {
  @media (min-width: 1441px) {
    @include font-size(1.625);
  }

  @media (max-width: 1440px) {
    @include font-size(1.5);
  }

  @media (max-width: 991px) {
    @include font-size(1.375);
  }
}

h5,
.h5 {
  @media (min-width: 1441px) {
    @include font-size(1.375);
  }

  @media (max-width: 1440px) {
    @include font-size(1.25);
  }

  @media (max-width: 991px) {
    @include font-size(1.125);
  }
}

h6,
.h6 {
  @media (min-width: 1441px) {
    @include font-size(1.125);
  }

  @media (max-width: 1440px) {
    @include font-size(1);
  }
}

ul {
  margin: 0;
  padding: 0;
}

p {
  @include font-size(1);
  color: $base-color;
  line-height: 1.6;
  font-weight: $font-medium;
  @media (min-width: 1440px) {
    @include font-size(1.125);
    line-height: 1.8;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

img {
  border: 0;
  @include no-select;
  outline: 0 !important;
}

a {
  color: $primary-color;
  text-decoration: $underline;
  &:hover {
    color: $primary-color;
  }
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: $none;
}

figure {
  margin: 0;
}

hr {
  border-color: rgba($black-color, 0.3);
}

.wrapper {
  padding-top: 50px;
  padding-bottom: 50px;
  @media (max-width: 1199.98px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.fill-primary {
  fill: $primary-color;
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $grey-color-400;
  @include border-radius(0px);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba($primary-red, 0.95);
  @include border-radius(0px);
}

// Placeholder CSS
::placeholder {
  color: rgba($black-color, 0.3);
}

:-ms-input-placeholder {
  color: rgba($black-color, 0.3);
}

::-ms-input-placeholder {
  color: rgba($black-color, 0.3);
}

input::-webkit-calendar-picker-indicator {
  @include opacity(0);
  position: $absolute;
  width: 100%;
  top: 0;
  height: 100%;
  left: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-text-fill-color: $black-color;
  -webkit-background-clip: unset !important;
  -webkit-box-shadow: none !important;
}

input {
  &[type="date"],
  &[type="datetime-local"] {
    background-repeat: $no-repeat;
    background-position: 96%;
    background-size: 18px;
  }
}

/* input[type="time"] {background:$white-color url(../img/icons/clock_icon.svg)no-repeat; background-position:96% center; background-size:18px;} */

// Button CSS
label {
  color: $label-color;
  font-weight: $font-medium;
  font-size: inherit;
  margin-bottom: 5px;
  @include font-size(1);
}

button {
  outline: 0 !important;
  box-shadow: $none !important;
  @include transition(all, 0.3s, ease-in-out);
}

.btn {
  cursor: $pointer;
  white-space: nowrap;
  @include transition(all, 0.3s, ease-in-out);
  padding: 0.5rem 1rem;
  @include border-radius(8px);
  &[disabled],
  &.disabled {
    @include opacity(0.5);
    color: $white-color;
    pointer-events: $none;
  }

  &:active,
  &:focus {
    background: linear-gradient(
      180deg,
      $primary-color-500 0%,
      $primary-color 100%
    ) !important;
    color: $white-color;
    border-color: $primary-color !important;
  }

  &-lg {
    min-width: 170px;
    padding: 0.76rem 1.5rem;

    @media (min-width: 1441px) {
      padding: 1rem 1.8rem;
      font-size: $f16 + 2;
      min-height: 50px;
    }
    @media (max-width: 820px) {
      min-width: 140px;
      font-size: $f12 + 3;
    }
  }

  &-md {
    @include border-radius(8px);
    min-width: 130px;
    @media (min-width: 1441px) {
      min-height: 42px;
    }
  }

  &-sm {
    font-size: $f12 + 2;
    min-height: 20px;
    min-width: 80px;
    padding: 0.38rem 0.78rem;
  }

  &-xs {
    min-height: 28px;
    min-width: 100px;
    text-align: $center;
    @include flexCenter();
    @include border-radius(2px);

    @media (min-width: 1440px) {
      min-width: 125px;
    }

    img {
      width: 20px;
    }
  }

  &-primary {
    background: linear-gradient(
      180deg,
      $primary-color 0%,
      $primary-color-500 100%
    );
    border: 2px solid $primary-color !important;
    color: $white-color;
    @include flexCenter;

    &:hover {
      background: linear-gradient(
        180deg,
        $primary-color-500 0%,
        $primary-color 100%
      );
      color: $white-color;
      border-color: $primary-color;
    }
    &:active,
    &:focus {
      background: linear-gradient(
        180deg,
        $primary-color-500 0%,
        $primary-color 100%
      );
      color: $white-color;
      border-color: $primary-color;
    }
  }
  &-primary-red {
    @include border-radius(90px);
    background: linear-gradient(180deg, $primary-red 0%, $red-dark 100%);
    border: none;
    color: $white-color;
    @include flexCenter;
    &:hover {
      background: linear-gradient(180deg, $red-dark 0%, $primary-red 100%);
      color: $white-color;
      border-color: $red-dark;
    }
    &:active,
    &:focus {
      background: linear-gradient(180deg, $red-dark 0%, $primary-red 100%);
      color: $white-color !important;
      border-color: $red-dark !important;
    }
  }
  &-secondary {
    background: $black-color-700;
    color: $white-color;
    border: 0;

    &:hover {
      background: $black-color-100;
      color: $white-color;
    }
  }

  &-outline-primary {
    background: $transparent;
    color: $base-color;
    border-color: $primary-color;
    @include flexCenter;

    &:hover,
    &:focus {
      background: $primary-color;
      color: $white-color !important;
      border-color: $primary-color;
    }
  }

  &-transparent {
    background: $transparent;
    border-color: $transparent;
    color: $primary-color;

    &:hover,
    &:focus {
      color: $primary-color;
      background: transparent !important;
      border-color: transparent !important;
    }
  }

  &-skyblue {
    background-color: var(--primary-color-200);
    border-color: var(--primary-color-200);
    color: $black-color-100;
    &:hover,
    &:focus,
    &:active {
      color: $black-color-100;
      background-color: var(--primary-color-200) !important;
      border-color: var(--primary-color-200) !important;
      @extend .btn-skyblue;
    }
  }

  &-white {
    background-color: $white-color;
    border-color: $white-color;
    color: $base-color;
    &:hover,
    &:focus,
    &:active {
      color: $primary-color;
      background-color: var(--primary-color);
      border-color: var(--primary-color) !important;
      color: $white-color !important;
      img {
        filter: brightness(100);
      }
    }
  }

  &-outline-black {
    background: $transparent;
    color: $base-color;
    border-color: $black-color;
    @include flexCenter;

    &:hover,
    &:focus {
      background: $black-color;
      color: $white-color !important;
      border-color: $white-color;
    }
  }
  // otlie black ends
  &-light-primary {
    background: rgba($primary-color, 0.15);
    color: $base-color;
    border-color: rgba($primary-color, 0.3);
    @include flexCenter;

    &:hover,
    &:focus {
      background: $primary-color;
      color: $white-color;
      border-color: $primary-color;
    }
  }
}

.form-group {
  position: $relative;
  margin: 0 0 20px;

  @media (max-width: 991px) {
    margin: 0 0 15px;
  }
}

.form-control {
  display: $block;
  width: 100%;
  padding: 0.422rem 0.875rem;
  @include font-size(1);
  font-weight: $font-regular;
  line-height: 1.5;
  color: $base-color;
  background-color: $white-color;
  background-clip: padding-box;
  border: 1px solid #dbdade;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  @include border-radius(10px);
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  @media (min-width: 1280px) {
    min-height: 48px;
  }
  &:focus {
    color: $base-color;
    background-color: $white-color;
    border-color: $primary-color;
    outline: 0;
    box-shadow: 0 0.125rem 0.25rem rgba(165, 163, 174, 0.3);
  }
}
.form-control::-moz-placeholder,
.form-control:focus::-moz-placeholder {
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.form-control::placeholder,
.form-control:focus::placeholder {
  transition: all 0.2s ease;
}

html:not([dir="rtl"]) .form-control:focus::-moz-placeholder {
  transform: translateX(4px);
}

html:not([dir="rtl"]) .form-control:focus::placeholder {
  transform: translateX(4px);
}

[dir="rtl"] .form-control:focus::-moz-placeholder {
  transform: translateX(-4px);
}

[dir="rtl"] .form-control:focus::placeholder {
  transform: translateX(-4px);
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control[type="file"] {
  overflow: hidden;
}

.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}

.form-control::-moz-placeholder {
  color: #b7b5be;
  opacity: 1;
}

.form-control::placeholder {
  color: #b7b5be;
  opacity: 1;
}

.form-control:disabled {
  background-color: rgba(75, 70, 92, 0.08);
  opacity: 1;
}

.form-control::file-selector-button {
  padding: 0.422rem 0.875rem;
  margin: -0.422rem -0.875rem;
  -webkit-margin-end: 0.875rem;
  margin-inline-end: 0.875rem;
  color: #6f6b7d;
  background-color: #fff;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: all 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #f2f2f2;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.422rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #6f6b7d;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext:focus {
  outline: 0;
}

.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.532rem + 2px);
  padding: 0.266rem 0.625rem;
  font-size: 0.8125rem;
  border-radius: 0.25rem;
}

.form-control-sm::file-selector-button {
  padding: 0.266rem 0.625rem;
  margin: -0.266rem -0.625rem;
  -webkit-margin-end: 0.625rem;
  margin-inline-end: 0.625rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1.188rem + 2px);
  padding: 0.594rem 1rem;
  font-size: 1.125rem;
  border-radius: 0.5rem;
}

.form-control-lg::file-selector-button {
  padding: 0.594rem 1rem;
  margin: -0.594rem -1rem;
  -webkit-margin-end: 1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.844rem + 2px);
}

textarea.form-control-sm {
  min-height: calc(1.5em + 0.532rem + 2px);
}

textarea.form-control-lg {
  min-height: calc(1.5em + 1.188rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 0.844rem + 2px);
  padding: 0.422rem;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: 0.375rem;
}

.form-control-color::-webkit-color-swatch {
  border-radius: 0.375rem;
}

.form-control-color.form-control-sm {
  height: calc(1.5em + 0.532rem + 2px);
}

.form-control-color.form-control-lg {
  height: calc(1.5em + 1.188rem + 2px);
}

// Form Select
.form-select {
  display: block;
  width: 100%;
  padding: 0.422rem 2.45rem 0.422rem 0.875rem;
  -moz-padding-start: calc(0.875rem - 3px);
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #6f6b7d;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 7.5L10 12.5L15 7.5' stroke='%236f6b7d' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M5 7.5L10 12.5L15 7.5' stroke='white' stroke-opacity='0.2' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0.875rem center;
  background-size: 22px 20px;
  border: 1px solid #dbdade;
  border-radius: 0.375rem;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}

.form-select:focus {
  border-color: #7367f0;
  outline: 0;
  box-shadow: 0 0.125rem 0.25rem rgba(165, 163, 174, 0.3);
}

.form-select[multiple],
.form-select[size]:not([size="1"]) {
  padding-right: 0.875rem;
  background-image: none;
}

.form-select:disabled {
  color: #a5a3ae;
  background-color: rgba(75, 70, 92, 0.08);
}

.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #6f6b7d;
}

.form-select-sm {
  padding-top: 0.266rem;
  padding-bottom: 0.266rem;
  padding-left: 0.625rem;
  font-size: 0.8125rem;
  border-radius: 0.25rem;
}

.form-select-lg {
  padding-top: 0.594rem;
  padding-bottom: 0.594rem;
  padding-left: 1rem;
  font-size: 1.125rem;
  border-radius: 0.5rem;
}

.invalid-error {
  border-color: $red-color !important;
}

.error {
  color: $red-color;
  font-size: $f12 + 2;
  margin: 4px 0 0;
  text-align: $left;
}

.main_wrapper {
  @media (max-width: 1199.98px) {
    padding-top: 90px !important;
  }
}

.form-label {
  display: inline-block;
}

.text-orange {
  color: $primary-red;
}
.rounded-50 {
  @include border-radius(50px);
}
.rounded-15 {
  @include border-radius(15px);
}

.no_data {
  min-height: 250px;
  @include flexCenter;
  @include font-size(1.75);
  line-height: 1.3;
  background: rgba($primary-color, 0.15);
  color: $primary-color;
  font-weight: $font-bold;
  @media (min-width: 1200px) {
    min-height: 400px;
  }
}

.fs-42 {
  @media (min-width: 1440px) {
    @include font-size(2.625);
  }
}

.my-cursor-pointer {
  cursor: pointer;
}

.text {
  &-primary {
    color: $primary-color !important;
  }
}
