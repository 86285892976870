@import "../../../../Assets/scss/base";

.common_pages {
  position: $relative;

  h2,
  .h2 {
    color: $black-color-100;
    margin-bottom: 1rem;

    &+span {
      color: $primary-color;
      margin-bottom: 0.5rem;
      display: $inline-block;
    }
  }

  p {
    color: $light-purple;
  }

  .last_update {
    font-weight: $font-semibold;
    color: $black-color;
    border-bottom: 1px solid $grey-color;
    padding-bottom: 15px;
    margin-bottom: 10px;
    line-height: 1.3;
    @include flexbox;
    @include align-items(center);
    overflow-wrap: break-word;

    @media (max-width: 767.98px) {
      @include font-size(0.938);
    }

    em {
      margin-right: 5px;
    }
  }
}

// terms and privacy pages css
.nesting_list {
  ol {
    counter-reset: item;
    list-style: $none;
    position: $relative;
    padding-left: 2.5rem;
    margin: 0;
    &:first-child>li>ol {
      padding-left: 0;
      margin-bottom: 1.8rem; 
      &>li {
        &::before {
          left: -2.5rem;
          content: counters(item, ".") " ";
        }
      }
    }
    li {
      counter-increment: item;
      @include font-size(1);
      line-height: 1.5;
      margin-bottom: 0.5rem;
      @media (min-width:1200px) {
        @include font-size(1.2);
        line-height: 1.75;
      }
      &:before {
        margin-right: 0px;
        content: counters(item, ".") " .";
        display: $inline-block;
        font-weight: $font-semibold;
        position: $absolute;
        left: 0;
      }      

      ol {
        ol {
          padding-left: 4rem;
        }
      }

      strong {
        margin-bottom: 1rem;
      }
    }

    &[type="a"] {
      list-style-type: none !important;

      li {
        counter-increment: section;

        &::before {
          content: "(" counter(section, lower-alpha) ") ";
        }
      }
    }
  }

  .type-a {
    list-style-type: lower-alpha !important;
  }

}

// policy page css
.common_policy {
  .policy_secn {
    &:not(:last-child) {
      margin-bottom: 30px;

      @media (min-width:1200px) {
        margin-bottom: 50px;
      }

    }

    ul {
      margin-left: 20px;

      li {
        padding-left: 20px;
        font-weight: $font-medium;
        @include font-size(1);
        line-height: 1.5;
        overflow-wrap: break-word;

        &:not(:last-child) {
          margin-bottom: 5px;
        }

        &:last-child {
          margin-bottom: 5px;
        }
      }
    }

    p {
      font-weight: $font-medium;
      @include font-size(1);
      line-height: 1.5;
      overflow-wrap: break-word;

      @media (min-width:1200px) {
        margin-bottom: 1.5rem;

      }
    }

    span {
      font-weight: $font-regular;
      @include font-size(1);
      line-height: 1.5;
      overflow-wrap: break-word;

    }

    h3,
    .h4 {
      margin-bottom: 1rem;
    }
  }
}