@import "../../../Assets/scss/_base.scss";

// footer started
footer {
  background-color: $black-medium;
  .footer_links {
    li {
      @include font-size(1);
      line-height: 1.3;
      text-align: $left;
      color: var(--white-color);
      border-right: 2px solid $white-color;
      display: $inline-block;
      padding: 0 15px;
      cursor: $pointer;
      @include transition(all, 0.3s, ease-in-out);

      &:hover {
        color: $primary-color;
        text-decoration: underline;
      }

      &:last-child {
        border: $none;
      }

      &:first-child {
        padding-left: 0;
      }
    }
  }
}

.footer_items {
  display: grid;
  grid-template-columns: 70% 30%;
  padding: 20px 0;
  @include align-items(center);

  @media (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
    padding: 10px 0;
    gap: 0.75rem;
    text-align: $center;
  }
}

.social_footer {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    @include flexbox;
    @include justify-content(center);
    gap: 0.75rem;

    @media (min-width: 768px) {
      @include justify-content(flex-end);
      padding-right: 1rem;
    }

    li {
      @include flexbox;
      @include align-items(center);

      em {
        img {
          cursor: $pointer;
        }
      }
    }
  }
}
