@import "../../../Assets/scss/base";

header {
  width: 100%;
  @include transition(all, 0.7s, ease-in-out);
  position: -webkit-sticky;
  position: $sticky;
  top: 0;
  background-color: $white-color;
  z-index: 1030;
  &.is-sticky {
    position: $fixed;
    -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); 
    @include transition(all, 0.7s, ease-in-out);
  }
  .navbar {
    .main_navbar {
      .navbar-nav {
        .nav-item {
        padding: 3px 0;
          width: 100%;        
          @media (max-width: 1199.98px) {
            border-bottom: 0px solid rgba($base-color, 0.15);
          }

          @media (max-width: 1199.98px) {
            padding: 3px 0;
            &:last-child {
              padding-bottom: 5px;
              margin-bottom: 10px;
            }
          }

          .nav-link {
            padding-left: 0px;
            padding-right: 0px;
            color: rgba($black-color, 0.5);
            @include font-size(1);
            line-height: 1.5;
            font-weight: $font-medium;
            cursor: $pointer;
            position: $relative;
            @media (min-width: 1200px) {
              @include font-size(1.3);
            }

            &.disabled {
              color: rgba($black-color-100, 0.5) !important;
              pointer-events: $none;

              &:after {
                opacity: 0;
              }
            }

            &::after {
              content: "";
              width: 0;
              height: 3px;
              @include position($absolute, $bottom: 0, $left: 0);
              @include border-radius(5px);
              background: $transparent;
              @include transition(all, 0.6s, ease-in-out);
              @media (min-width: 1200px) {
                bottom: 0rem;
              }
            }

            &:hover {
              &::after {
                width: 100%;
                @include border-radius(3px);
                background: linear-gradient(
                  90deg,
                  $primary-red 0%,
                  rgba(240, 90, 94, 0) 100%
                );
              }
            }
            &.donate_link {
              &::after {
                display: $none;
              }
            }
          }

          &.active,
          &:hover {
            .nav-link {
              color: $black-color;
              em {
                svg {
                  path {
                    fill: $base-color;
                  }
                }
              }
              &::after {
                width: 100%;
                background: linear-gradient(
                  90deg,
                  $primary-red 0%,
                  rgba(240, 90, 94, 0) 100%
                );
              }
            }
          }
        }

        .member-link {
          padding: 0.5rem 1.5rem;
          margin: 1rem 0;
          width: 100%;
          @media (min-width: 1200px) {
            min-height: 45px;
          }
          @media (min-width: 1600px) {
            @include font-size(1.3);
          }
          @media (max-width: 1199.98px) {
            margin: 10px 0;
            max-width: initial;
          }
        }
      }
      .extralinks {
        line-height: 1.3;
        font-weight: $font-medium;
        cursor: $pointer;

        @media (min-width: 1200px) and (max-width: 1440px) {
          font-size: $f16 - 2px;
        }
      }
    }

    .navbar-brand {
      outline: 0;
      z-index: 1;
      img {
        max-width: 200px;
        width: 100%;
        @media (min-width: 1200px) {
          max-width: 200px;
        }
       
        @media (max-width: 1199.98px) {
          max-width: 180px;
        }
        @media (max-width: 320px) {
          max-width: 160px;
        }
      }
    }
  }
  .donate_link {
    margin-right: 0;
    &::after {
      display: $none;
    }
  }
}

/* Hamburger Menu */
.hamburger {
  padding: 0;
  border:0 !important; 
  @include flexbox-important;
  background: $transparent;
}
  .main_navbar {
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
    background: $white-color;
    padding: 5px 15px;
    width: 400px;
    position: $fixed;
    left: auto;
    right: 0;
    top: 86px;
    height: calc(100% - 86px);
    overflow-y: $auto;
    @include transform(translateX(110%));
    @include transition(all, 0.5s, ease-in-out);
    z-index: -1;
    display: $block !important;
    scrollbar-color: $primary-red rgba($grey-color-400, 0.95);
    scrollbar-width: thin;
    @media (max-width:1199.98px) {
      width:300px;
      top: 81px;
      height: calc(100% - 81px);
    }
    &_menu {
      @include flex-direction(column);
      flex-direction: column !important;
      overflow-x: $hidden;
      overflow-y: $auto;
      scrollbar-color: $primary-color rgba($grey-color-400, 0.95);
      scrollbar-width: thin;

      li {
        padding: 10px 0;
      }
    }

    &.show {
      display: $block !important;
      background: $white-color;
      @include transform(translateX(0%));
      z-index: 99999;
      @include border-radius(0);
    }

    .nav-item {
      display: $block;
    }
  }

  .is-menu-active {
    position: $relative;
    overflow: $hidden;

    .main_head {
      &.is-sticky {
        .hamburger {
        }
      }
    }

    .hamburger {
      position: $relative;
      z-index: 1031;

      span {
        background: url("../../../Assets/Images/icons/cross_icon.svg") no-repeat;
        background-size: 20px;
        height: 1.5em;
        background-position: $center;
      }
    }

    &::before {
      position: $fixed;
      left: 0;
      top: 0;
      content: "";
      width: 100%;
      background: rgba($base-color, 0.5);
      height: 100%;
      z-index: 1021;
      @include transition(all, 0.3s, ease-in-out);
      display: $none;
    }

    .main_navbar {
      display: $block !important;
      background: $white-color;
      @include transform(translateX(0%));
      z-index: 9999;
    }
  }


@media (max-width: 575.98px) {
  .main_navbar {
    width: 100%;
  }
}

.offcanvas-backdrops {
    position: $fixed;
    left: 0;
    top: 85px;
    z-index: 1040;
    width: 100vw;
    height: calc(100% - 85px);
    background-color: $black-color;
    opacity: 0;
    @media (max-width:1199.98px) {
      top: 80px;
      height: calc(100% - 80px);
    }
    &.show {
      opacity: 0.5;
    }
  
}

.donateNow {
  animation: zoom-in-zoom-out 2s ease-out infinite;
  transform-origin: center;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.15, 1.15);
  }

  100% {
    transform: scale(1, 1);
  }
}

@-webkit-keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.15, 1.15);
  }

  100% {
    transform: scale(1, 1);
  }
}

.donate_link {
  margin-right: 0;
  &.desktop {
    @media (max-width: 1199.98px) {
      display: $none;
    }
  }
}
