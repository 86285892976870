@import "../../../Assets/scss/base.scss";

// core css modal ends
.ReactModal {
  &__Body {
    &--open {
      overflow: $hidden;
    }
  }
}
.react_modal_content {
  pointer-events: $auto;
}

.Overlay {
  position: $fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: $auto;
  overflow-x: $hidden;
  background: rgba(0, 0, 0, 0.65);
  @include transition(opacity, 0.15s, linear);
  z-index: 1057;
}

.Modal {
  padding: 0px;
  margin: 0 $auto;
  background: transparent;
  outline: $none !important;
  &.react_modal_main {
    outline: $none !important;
    display: $block;
    .react_modal_content {
      position: $relative;
      background: $white-color;
      @include border-radius(15px);
      width: 100%;
      @media (max-width: 767px) {
        width: 100%;
      }
      span {
        font-size: $f16;
        line-height: 1.3;
        @media (min-width: 1200px) {
          font-size: $f16;
        }
      }
    }
  }

  .modal-header {
    padding-right: 40px;
    padding-bottom: 1rem;
    margin: 0 0 0px;
    border-bottom: 1px solid $grey-color-300;
    &.is-empty {
      padding: 0 !important;
      border-bottom: none;
    }
    .modal-title {
      color: $black-color;
      word-break: break-word;
    }
  }
  @at-root {
    .close_btn {
      background-color: $white-color;
      @include border-radius(0.375rem);
      width: 32px;
      height: 32px;
      position: $absolute;
      border: 0;
      right: 10px;
      top: 10px;
      line-height: 1;
      padding: 0.44rem;
      z-index: 1;
      @include transform(translate(23px, -25px));

      svg {
        max-width: 15px;
        max-height: 15px;
        cursor: $pointer;
        opacity: 0.5;
        path {
          fill: $base-color;
        }
      }
      &:hover {
        background: $white-color;
        @include transform(translate(20px, -20px));
      }
    }
  }
  .close_btn {
    box-shadow: 0 0.125rem 0.25rem rgba($base-color, 10%) !important;
  }
}

// share modal css

.social_list {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;

    @media (max-width: 767px) {
      gap: 1rem;
    }
    @media (max-width: 575.98px) {
      grid-template-columns: repeat(2, 1fr);
    }
    li {
      @include flexbox;
      @include flex-direction(column);
      @include align-items(center);
      span {
        @include flexbox;
        @include flex-flow(column);
        @include align-items(center);
        cursor: pointer;

        em {
          margin-bottom: 10px;
        }

        line-height: 1.3;
        font-weight: $font-medium;
        color: $black-color;
      }
    }
  }
}

.modal-body {
  .form-label {
    font-weight: $font-semibold;
    color: $black-color;
    @include font-size(0.875);
  }
}
