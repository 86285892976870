@import "../../../Assets/scss/base";

.partners_slider {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;

  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);

  @media (min-width: 576px) {
    margin-left: calc((100% - 540px) / 2);
  }

  @media (min-width: 768px) {
    margin-left: calc((100% - 720px) / 2);
  }

  @media (min-width: 992px) {
    margin-left: calc((100% - 960px) / 2);
  }

  @media (min-width: 1200px) {
    margin-left: calc((100% - 1140px) / 2);
  }

  @media (min-width: 1400px) {
    margin-left: calc((100% - 1320px) / 2);
  }
}

.main_partner {
  .partners_slider {
    &-box {
      overflow: hidden;
      position: relative;
      @include transition(all, 0.5s, ease-in-out);
      @include border-radius(10px);
      height: 300px;
      @media (max-width: 767.98px) {
        height: 250px;
      }
      @media (min-width: 1200px) {
        height: 350px;
      }

      &::before {
        background: linear-gradient(
          to top,
          rgba(0, 0, 0, 0.7) 0%,
          transparent 50%
        );
        transform: translateY(20%);
        /* transition: transform .85s cubic-bezier(0.23, 1, 0.32, 1); */
        position: $absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: "";
        opacity: 1;
        z-index: 1;
        @include transition(all, 0.5s, ease-in-out);
      }

      img {
        max-width: 100%;
        width: 100%;
        max-height: 300px;
        height: 100%;
        @include border-radius(10px);
        outline: 0;
        @include transition(all, 0.5s, ease-in-out);

        @media (min-width: 1200px) {
          max-height: 350px;
        }

        @media (max-width: 767.98px) {
          max-height: 250px;
        }
      }

      &:hover {
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
        &::before {
          transform: translateY(0%);
        }

        img {
          transform: scale(1.1);
        }
      }
    }
  }
}

.partner_data {
  @media (min-width: 992px) {
    max-width: 470px;
  }
}

// partners logos
.common_grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  @media (min-width: 768px) {
    gap: 1.5rem;
  }
  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 1200px) {
    grid-template-columns: repeat(4, 1fr);
  }
  &_card {
    text-align: center;
    @include flexCenter;
    img {
      object-fit: scale-down;
      max-height: 150px;
    }
  }
}
