// root
:root {
  --base-color: #5d6371;
  --primary-color: #f05a5e;
  --primary-color-500: #ce3d41;
  --black-color: #000000;
  --black-color-100: #191919;
  --black-color-200: #181b31;
  --black-color-300: #181b30;
  --black-color-300: #191d18;

  --white-color: #ffffff;
  --light-purple: #797795;
  --dark-blue-color: #18125d;

  --pink-color: #df26e3;
  --red-primary: #f05a5e;
  --red-dark: #ce3d41;
  --grey-bg: #f9f9fb;
  --ice-blue: #faffff;
  --ice-blue-100: #e4f6fa;
}

// _colors
$base-color: #5d6371;
$black-color: #000000;
$black-color-100: #191919;
$black-color-200: #181b31;
$black-color-300: #181b30;
$black-color-400: #191d18;
$black-color-500: #313030;
$black-medium: #292d36;
$light-purple: #797795;

$body-color: #ffffff;
$white-color: #ffffff;

$primary-color: #f05a5e;
$primary-color-500: #ce3d41;

$secondary-color: #0683a0;

//dark-blue color

$blue-color-500: #423d7b;
//purple colors

$purple-color-500: #685dd8;

$black-color-700: #000031;
$label-color: #5d596c;

$sky-blue-color: #1db9fc;
$ice-blue: #faffff;
$ice-blue-100: #e4f6fa;

$grey-color: #cccccc;
$grey-color-100: #a5a3ae;
$grey-color-200: #a1a1c5;
$grey-color-300: #d6d6d6;
$grey-color-400: #cfcede;
$grey-color-500: #f8fcfa;
$grey-color-700: #8e8d8d;
$grey-light: #dbdade;
$grey-bg: #f9f9fb;

$error-color: #ee7c87;
$red-color: #ff0000;
$danger-color: #fc2626;
$primary-red: #f05a5e;
$red-dark: #ce3d41;

$success-color: #60d108;
$warning-color: #e9c405;
$info-color: #e97205;
$pink-color: #df26e3;
$orange-color: #f2903f;
$orange-color-100: #fff9f4;
$transparent: transparent;
