// _fonts.scss

@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
$Public-font: "Rubik", sans-serif;

$font-black: 900;
$font-extrabold: 800;
$font-bold: 700;
$font-semibold: 600;
$font-medium: 500;
$font-regular: 400;
$font-light: 300;
$font-normal: normal;

$f12: 12px;
$f14: 14px;
$f16: 16px;
$f20: 20px;
$f24: 24px;
$f30: 30px;
$f40: 40px;
$f50: 50px;
$filter-modal-height: 50vh;
