@import "../../../Assets/scss/base";

.bg_grey {
  background-color: $grey-bg;
}

main,
.main {
  @media (min-width: 1200px) {
    min-height: calc(100vh - 85px - 70px);
  }
}

.section {
  padding: 60px 0;

  @media (max-width: 1680px) {
    padding: 50px 0;
  }

  @media (max-width: 1199.98px) {
    padding: 40px 0;
  }

  @media (max-width: 767.98px) {
    padding: 30px 0;
  }
}

.common_titlebar {
  h2 {
    span {
      line-height: 1.3;
      display: block;
      font-weight: $font-medium;
    }
  }
}

// main hero banner
.herobanner {
  background: $white-color;
  width: 100%;
  height: calc(100vh - 85px);

  @media (max-width: 1199.98px) {
    height: calc(100vh - 81px);
  }
  @media (min-width: 576px) and (max-width: 767.98px) {
    height: 100%;
  }
  &_card {
    width: 100%;
    height: 100%;
    position: $relative;
    @include flexCenter;

    &::before {
      position: $absolute;
      content: "";
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.25);
    }

    & > img {
      height: 100%;
      width: 100%;
      object-fit: $cover;
    }

    &_box {
      max-width: 900px;
      width: 100%;
      text-align: $center;
      position: $absolute;
      bottom: 10%;
      left: 50%;
      transform: translateX(-50%);
      color: var(--white-color);
    }

    &_content {
      @include flexbox;
      @include flex-flow(column);
      @include font-size(20);
      padding: 0 0.5rem;

      h1,
      .h1 {
        color: $white-color;
        text-transform: $capitalize;
      }

      button {
        max-width: 200px;
        margin: 1rem auto;
      }
    }
  }
}

// our story css
.community_grid_item {
  padding: 0px;

  @media (max-width: 767.98px) {
    padding: 0 1.5rem;
  }

  h2,
  .h2 {
    margin-bottom: 0;

    @media (min-width: 1440px) {
      @include font-size(2.75);
    }
  }

  span {
    @include font-size(0.75);
    color: $black-color-300;
    font-weight: $font-semibold;
  }
}

// community secn css
.community_secn {
  background: var(--primary-color-200);
}

// major partner
.majorpartner_card {
  video {
    @include border-radius(12px);
    max-height: 400px;
    width: 100%;
    height: 100%;
    border: 0;
    object-fit: $cover;
  }
}
// inspired css

.inspired_blocks {
  &_card {
    background-color: $white-color;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    box-shadow: 0 25px 70px rgba(0, 0, 0, 0.07);
    padding: 1.5rem;
    @include border-radius(10px);
    @include flexbox;
    @include flex-flow(column);
    gap: 10px;
    height: 100%;

    @media (max-width: 991px) {
      padding: 45px 10%;
    }

    @media (min-width: 1280px) {
      padding: 40px 30px;
    }

    @media (min-width: 1440px) {
      padding: 40px 35px;
    }

    em {
      margin-bottom: 1rem;

      @media (max-width: 1199.98px) {
        margin-bottom: 0.5rem;
      }

      img {
        max-width: 35px;
        max-height: 35px;
        min-width: 35px;
        min-height: 35px;

        @media (min-width: 1200px) {
          max-width: 50px;
          max-height: 50px;
          min-width: 50px;
          min-height: 50px;
        }
      }
    }

    span {
      color: $base-color;
      @include font-size(1);
      line-height: 1.3;
      margin-top: 5px;
      font-weight: $font-medium;

      @media (min-width: 991px) {
        @include font-size(0.875);
      }

      @media (min-width: 992px) {
        @include font-size(1.063);
      }
    }
  }
}

// major partner testimonial block secn css
.major_testimonial_secn {
  .testimonialblock {
    @media (max-width: 991.98px) {
      padding-bottom: 15px;
    }

    @media (min-width: 992px) {
      padding-right: 50px;
    }
  }

  .testimonial_card {
    max-width: 400px;
    margin: 0 $auto;
    @include flexbox;
    display: flex !important;
    @include flex-flow(column);
    @include justify-content(space-between);
    @include align-items(center);
    gap: 20px;
    text-align: $center;

    @media (min-width: 992px) and (max-width: 1199.98px) {
      max-width: 350px;
    }

    @media (min-width: 1200px) {
      max-width: 400px;
    }

    p {
      margin-bottom: 0.5rem;
      color: $black-color;
    }

    span {
      @include font-size(0.813);
      font-weight: $font-medium;
      line-height: 1.3;
      color: $black-color-100;
      text-transform: $uppercase;
    }
  }

  .sliderProgressbar {
    max-width: 300px;
    margin: 40px auto 0 auto;

    @media (max-width: 767.98px) {
      margin-top: 50px;
    }

    .progress-bar {
      position: $relative;
      bottom: 0;
      left: 0;
      height: 2px;
      background-color: $grey-color-400;
      width: 100%;
      margin: 0 15px;
      @include transition(width, 0.4s, ease-in-out);
    }

    .progress {
      height: 100%;
      background-color: $black-color-100;
      width: 0;
      position: $absolute;
      top: 50%;
      left: 50%;
      @include transform(translate(-50%, -50%));
      transform-origin: center;
      @include transition(width, 0.4s, ease-in-out);
    }

    span {
      @include font-size(1);
      font-weight: $font-medium;
      color: $black-color-100;
    }
  }

  .majorpartner_block {
    @media (max-width: 767.98px) {
      margin-top: 30px;
    }

    figure {
      @include border-radius(10px);
      background-color: rgba($primary-color, 0.15);

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        max-height: 450px;
        @include border-radius(10px);
      }
    }

    button {
      text-transform: $uppercase;
      width: auto;
    }
  }
}

.pulsating_logo {
  img {
    cursor: $pointer;
  }
}

.logopartner {
  &_logo {
    img {
      @include border-radius(10px);
      width: 100%;
      height: 100%;
      max-width: 300px;
    }
  }

  &_content {
    color: $black-color;
    line-height: 1.3;

    em {
      @include font-size(2);
      font-weight: $font-bold;
      font-style: normal;
    }

    span {
      @include font-size(1);
      font-weight: $font-semibold;
      font-style: italic;
    }
  }
}

.animating_logo {
  img {
    max-width: 120px;
    width: 100%;
  }
}
