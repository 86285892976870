@import "../../../../Assets/scss/base";

.contact_wrapper {
  background:
    url(../../../../Assets/Images/icons/map.svg) no-repeat,
    linear-gradient(155deg, $ice-blue 0%, $ice-blue-100 100%);
  background-size: auto;
  background-position: top left;
  width: 100%;
  height: 100%;
  position: $relative;
  .contactCard {
    border: none;
    padding: 1.5rem;
    @include border-radius(30px);
    @media (min-width: 1200px) {
      padding: 35px;
    }
    @media (max-width: 767px) {
      padding: 1rem;
    }
    .btn {
      @include border-radius(15px);
      @media (min-width: 1200px) {
        min-width: 210px;
      }
    }
    .live_chat {
      em {
        @include flexCenter;
        @include border-radius(100vmax);
        min-width: 40px;
        max-width: 40px;
        min-height: 40px;
        max-height: 40px;
        background: $primary-color;
        padding: 8px;
        margin-right: 5px;
        @media (min-width: 1200px) {
          min-width: 57px;
          max-width: 57px;
          min-height: 57px;
          max-height: 57px;
        }
        img {
          @media (max-width: 1200px) {
            max-width: 28px;
          }
        }
      }
      @include font-size(1.5);
      font-weight: $font-semibold;
      line-height: 1.3;
    }
  }
  // right side
  .addressBar {
    @media (max-width: 991px) {
      margin-top: 50px;
    }
    &Row {
      span {
        color: $light-purple;
        line-height: 1.5;
        font-weight: $font-semibold;
        text-decoration: underline;
      }
      & > *:last-child {
        span {
          text-decoration: none;
        }
      }
    }
  }
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      155deg,
      rgba($ice-blue, 0.25) 0%,
      rgba($ice-blue-100, 0.5) 100%
    );
  }
}
