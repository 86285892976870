@import "../../../Assets/scss/base";

.react-tel-input {
  .form-control {
    width: 100%;
    outline: none;
    padding: 6px 14px 6px 60px;
    @include border-radius(10px);

    &:focus {
      color: $base-color;
      background-color: $white-color;
      border-color: $primary-color;
      outline: 0;
      box-shadow: 0 0.125rem 0.25rem rgba(165, 163, 174, 0.3);
    }
  }

  .flag-dropdown {
    .selected-flag {
      &:focus {
        box-shadow: none;
        border-color: $transparent;
        &::before {
          box-shadow: $none;
          border-color: transparent;
        }
      }
    }
    &.open {
      .selected-flag {
        &:focus {
          box-shadow: none;
          border-color: $transparent;
          &::before {
            box-shadow: $none;
            border-color: transparent;
          }
        }

        &.open {
          &:focus {
            box-shadow: $none;
            border-color: $transparent;
            &::before {
              box-shadow: $none;
              border-color: $transparent;
            }
          }

          &::before {
            box-shadow: $none;
            border-color: $transparent;
          }
        }
      }
    }
  }
}

.disableClickClass {
  pointer-events: none;

  .form-control {
    width: 100%;
    outline: none;
    padding: 6px 14px 6px 0px;

    &:focus {
      color: $base-color;
      background-color: $white-color;
      border-color: $primary-color;
      outline: 0;
      box-shadow: 0 0.125rem 0.25rem rgba(165, 163, 174, 0.3);
    }
  }

  .flag-dropdown {
    position: $absolute;
    top: 0;
    bottom: 0;
    padding: 0;
    left: -10px;
    @include border-radius(3px 0 0 3px);
    display: $none;
  }
}
