@import "../../Assets/scss/base";

.custom_form_wrapper {
  max-width: 800px;
  margin: 0px $auto;
  background: $white-color;
  padding: 1.25rem;
  box-shadow:
    rgba(17, 17, 26, 0.05) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
  @include border-radius(20px);
  @media (max-width: 767px) {
    padding: 1rem;
  }
}

h2 {
  span {
  }
}

.Child_group_grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;
  @media (min-width: 576px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 768px) {
    gap: 2rem 2rem;
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 1200px) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.modal_actions {
  .view_action {
    svg {
      path {
      }
    }
  }
  .delete_action {
    svg {
      path {
        fill: $primary-color;
      }
    }
  }
}
